import React from "react";
import { Container, Typography } from "@material-ui/core";
import "source-sans-pro/source-sans-pro.css";

function NotFound() {
  return (
    <Container maxWidth='md'>
      <Typography variant='h1'>
        Page non trouvée 
      </Typography>
      <Typography variant='body1'>
        Désolé, il semble que vous tentiez d'accéder à une page qui n'existe pas.
      </Typography>
      <Typography variant='body1'>
        Veuillez vérifier l'orthographe de l'URL à laquelle vous tentiez d'accéder et réessayer.
      </Typography>
    </Container>
  );
}

export default NotFound;
