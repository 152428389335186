import React from "react";
import "./App.css";
import { Button, Container, Link } from "@material-ui/core";
import "source-sans-pro/source-sans-pro.css";
import logo from "./images/logo.png";
import banner from "./images/banner.jpg";
import { useLocation, Link as RouteLink } from "react-router-dom";

function getEnglishLocation(frenchLocation: string): string {
  let englishDomain = "https://myhealthlink.ca";

  if (frenchLocation === "/") {
    return englishDomain + "/";
  } else if (frenchLocation === "/au-sujet-de-nous") {
    return englishDomain + "/about-us";
  } else if (frenchLocation === "/configuration-de-votre-compteaide") {
    return englishDomain + "/account-set-up-help";
  } else if (frenchLocation === "/politique-de-confidentialite") {
    return englishDomain + "/privacy";
  }
  return englishDomain;
}

function Header() {
  let location = useLocation();

  return (
    <>
      <Container
        maxWidth='md'
        id="header"
      >
        <div style={{ display: "block", flex: 1000 }}>
          <RouteLink to='/'>
            <Button style={{ backgroundColor: "transparent" }}>
              <img src={logo} alt='logo' className='App-logo' />
            </Button>
          </RouteLink>
        </div>
        <div id="menu">
          <div id="menu-spacer"></div>
          <RouteLink to='/au-sujet-de-nous'>
            <Button>Au Sujet De Nous</Button>
          </RouteLink>
          <RouteLink to='/configuration-de-votre-compteaide'>
            <Button>Contactez Nous</Button>
          </RouteLink>
          <Link href={getEnglishLocation(location.pathname)}>
            <Button>English</Button>
          </Link>
        </div>
      </Container>
      <Container maxWidth='xl' style={{ padding: 0, marginBottom: "50px" }}>
        <section
          style={{
            backgroundImage: `url(${banner})`,
            backgroundPosition: "center",
            height: "172px",
          }}
        />
      </Container>
    </>
  );
}

export default Header;
