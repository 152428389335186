import React from "react";
import { Container, Link, Typography } from "@material-ui/core";
import "source-sans-pro/source-sans-pro.css";

function About() {
  return (
    <Container maxWidth='md'>
      <Typography variant='h1'>
        Au sujet de nous
      </Typography>
      <Typography variant='body1'>
        Ma Connexion Santé vous connecte au cabinet de votre médecin en offrant
        la fonction de prise de rendez-vous en ligne. C’est un moyen sécuritaire
        et pratique de fixer un rendez-vous – même lorsque la clinique est
        fermée.
      </Typography>
      <Typography variant='body1'>
        Ma Connexion Santé est proposé par Intrahealth. Veuillez consulter{" "}
        <Link href="https://www.intrahealth.com/">Intrahealth.com</Link> pour plus d'informations.
      </Typography>
      <Typography variant='h2'>
        Foire aux questions
      </Typography>
      <Typography variant='h3'>
        Qu’est-ce que Ma Connexion Santé?
      </Typography>
      <Typography variant='body1'>
        Ma Connexion Santé est un service en ligne qui permet aux patients de
        fixer un rendez-vous en ligne avec leur médecin.
      </Typography>
      <Typography variant='body1'>
        Cela est possible grâce au système provincial de DME, que de nombreux
        médecins du Nouveau-Brunswick utilisent dans leur clinique.
      </Typography>
      <Typography variant='h3'>
        Qu’est-ce qu’un DME?
      </Typography>
      <Typography variant='body1'>
        L’abréviation DME désigne l’expression dossier médical électronique.
        Cette technologie remplace les dossiers papier utilisés dans le passé
        par les médecins. L’information sur votre santé est ainsi accessible en
        ligne.
      </Typography>

      <Typography variant='body1'>
        En voici les avantages:
        <ul>
          <li>Accès plus rapide à vos résultats de test</li>
          <li>Tableaux électroniques de vos antécédents médicaux</li>
          <li>
            Permet au médecin d’accéder à votre information médicale en ligne
            n’importe où, n’importe quand
          </li>
          <li>Avise le médecin des ordonnances contradictoires</li>
        </ul>
      </Typography>

      <Typography variant='h3'>
        Qu’est-ce que le système provincial de DME?
      </Typography>
      <Typography variant='body1'>
        Le système provincial de DME est géré par Velante, une société
        appartenant à la Société médicale du Nouveau-Brunswick. C’est le seul
        système de DME au Nouveau-Brunswick qui est connecté au ministère de la
        Santé, ce qui signifie que des choses comme l’accès aux résultats de
        test, sont plus rapides.
      </Typography>
      <Typography variant='body1'>
        Au début de 2017, l’information sur la santé de plus de 400 000
        Néo-Brunswickois et Néo-Brunswickoises avait été saisie dans le système
        provincial de DME par leur fournisseur de soins de santé.{" "}
      </Typography>
      <Typography variant='h3'>
        Comment savoir si mon médecin offre Ma Connexion Santé?{" "}
      </Typography>
      <Typography variant='body1'>
        Certains médecins seulement du Nouveau-Brunswick offrent la prise de
        rendez-vous en ligne. Consultez la liste des médecins participants ici.
      </Typography>
      <Typography variant='h3'>
        J’aimerais fixer un rendez-vous en ligne. Que dois-je faire?
      </Typography>
      <Typography variant='body1'>
        Demandez un nom d’utilisateur et un mot de passe à votre médecin. La
        brochure vous aidera à démarrer lorsque vous aurez l’information
        nécessaire à votre connexion.
      </Typography>
      <Typography variant='h3'>
        Mes renseignements sont-ils protégés?
      </Typography>
      <Typography variant='body1'>
        Absolument. Le système provincial de DME se conforme à des règlements et
        à des normes plus sévères que celles des services bancaires en ligne. Ce
        niveau de sécurité s’applique également à Ma Connexion Santé.
      </Typography>
      <Typography variant='h3'>
        Pourquoi mon médecin n’offre-t-il pas ce service?
      </Typography>
      <Typography variant='body1'>
        Certains médecins seulement utilisent le système provincial de DME ou
        choisissent d’utiliser la fonction de prise de rendez-vous en ligne qui
        leur est offerte.
      </Typography>
      <Typography variant='body1'>
        Si vous souhaitez que la clinique de votre médecin offre ce service,
        nous vous encourageons à lui mentionner votre intérêt lors de votre
        prochaine visite. La clinique l’envisage peut-être déjà.
      </Typography>
      <Typography variant='body1'>
        Vous pouvez présenter une demande anonyme sur ce site Web. Sur la page
        d’accueil, cliquez sur « Trouver votre médecin » et cherchez le nom de
        votre médecin (assurez-vous d’avoir choisi la bonne région). Cliquez sur
        son nom, et une nouvelle page s’affichera et vous demandera de présenter
        une demande à votre médecin. Cliquez sur « Soumettre », et voilà, c’est
        terminé!
      </Typography>
      <Typography variant='h3'>
        J’essaie de fixer un rendez-vous en ligne sans succès.
      </Typography>
      <Typography variant='body1'>
        Utilisez-vous un ancien navigateur Internet? Cela pourrait être le
        problème. Faites la mise à jour de votre navigateur et réessayez. Si
        vous ne réussissez pas, appelez votre clinique.
      </Typography>
      <Typography variant='h3'>
        Est-ce que cela signifie que je peux accéder à mon dossier électronique
        en ligne?
      </Typography>
      <Typography variant='body1'>
        En ce moment, la seule fonction accessible est la prise de rendez-vous
        en ligne. Cependant, nous travaillons à l’élargissement du service et
        nous ajouterons d’autres fonctions à l’avenir.
      </Typography>
    </Container>
  );
}

export default About;
