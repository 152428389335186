import React from "react";
import { Container, Typography } from "@material-ui/core";
import "source-sans-pro/source-sans-pro.css";

function Contact() {
  return (
    <Container maxWidth='md'>
      <Typography variant='h1'>
        Configuration de votre compte/Aide
      </Typography>
      <Typography variant='body1'>
        Pour obtenir un compte Ma Connexion Santé, commencez par demander à
        votre médecin un nom d’utilisateur et un mot de passe. Veuillez noter
        que certains médecins seulement offrent ce service.
      </Typography>
      <Typography variant='body1'>
        Si vous avez besoin d’aide avec votre compte Ma Connexion Santé, vous
        devrez appeler le cabinet de votre médecin.
      </Typography>
    </Container>
  );
}

export default Contact;
