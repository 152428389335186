import React, { useState } from "react";
import { Link as RouteLink } from "react-router-dom";
import {
  Button,
  Container,
  Typography,
  Link,
  GridList,
  GridListTile,
  useMediaQuery,
  useTheme,
  Divider,
  Input,
} from "@material-ui/core";
import "source-sans-pro/source-sans-pro.css";
import NBMap from "./NBMap";
var providerDataJSON = require("./providerData");

function Providers() {
  const providerData: any[] = providerDataJSON;

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const getGridListCols = () => {
    if (matches) {
      return 2;
    }
    return 1;
  };

  var [doctorFilter, setDoctorFilter] = useState("");

  const aStyle = {
    textDecoration: "none",
    fontWeight: 600,
  };

  const buttonStyle = {
    borderRadius: "10px",
    marginTop: "3px",
    padding: "3px 10px",
    color: "black",
    borderWidth: 2,
  };

  var providers = providerData
    .filter(
      (provider) =>
        provider["LastName"].toLowerCase().includes(doctorFilter) |
        provider["FirstName"].toLowerCase().includes(doctorFilter) |
        provider["OrgName"].toLowerCase().includes(doctorFilter)
    )
    .map(function (provider: any) {
      return (
        <GridListTile key={provider["Key"]} style={{ margin: "10px 0" }}>
          <Typography variant='body1' style={{ fontWeight: 600 }}>
            {provider["LastName"]}, {provider["FirstName"]}
          </Typography>
          {/* <Typography variant='body2'>{provider.OrgName}</Typography> */}
          <Typography variant='body2'>
            {provider.City}, {provider.Province}
          </Typography>
          <Typography variant='body1'>
            <a href={provider.URL} style={aStyle}>
              <Button variant='outlined' color='primary' style={buttonStyle}>
                Rendez-vous
              </Button>
            </a>
          </Typography>
        </GridListTile>
      );
    });

  return (
    <React.Fragment>
      <Input
        placeholder='Recherche par nom (minimum de 3 caractères)'
        style={{ width: 400, paddingTop: 15 }}
        onChange={(e) =>
          setDoctorFilter(
            (doctorFilter = e.target.value.length >= 3 ? e.target.value : "")
          )
        }
      ></Input>

      <GridList
        cellHeight='auto'
        cols={getGridListCols()}
        style={{ margin: "20px 0" }}
      >
        {providers}
      </GridList>
      {providers.length === 0 ? (
        <Typography>Aucun médecin trouvé</Typography>
      ) : null}
    </React.Fragment>
  );
}

function Home() {
  return (
    <Container maxWidth='md'>
      <div style={{ display: "flex" }}>
        <div>
          <Typography variant='h1'>
            Bienvenue
          </Typography>
          <Typography variant='body1'>
            Ma Connexion Santé vous met en contact avec votre médecin et vous
            permet de fixer vos rendez-vous en ligne. C’est un moyen sûr et
            pratique de fixer vos rendez-vous – même lorsque la clinique est
            fermée. Pour plus d'informations, veuillez visiter notre{" "}
            <RouteLink to='/au-sujet-de-nous'>
              <Link>foire aux questions</Link>
            </RouteLink>.
          </Typography>
        </div>
        <div>
          <NBMap />
        </div>
      </div>

      <Typography
        variant='h2'
        style={{ textTransform: "uppercase", marginBottom: 0 }}
      >
        Trouvez mon médecin
      </Typography>
      <Divider style={{ backgroundColor: "primary" }} />

      <Container maxWidth='xl'>
        <Providers />
      </Container>
    </Container>
  );
}

export default Home;
