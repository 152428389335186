import React from "react";
import { Container, Typography } from "@material-ui/core";
import "source-sans-pro/source-sans-pro.css";

function PrivacyFrench() {
  return (
    <Container maxWidth='md'>
      <Typography variant='h1'>
        Politique de confidentialité
      </Typography>
      <Typography variant='body1'>
        Cette politique de confidentialité décrit comment Intrahealth Systems
        Limited («nous», «nous» ou «Intrahealth») peut collecter, utiliser et
        partager des informations vous concernant («vous» ou «utilisateur») que
        nous obtenons via intrahealth.com («Intrahealth Site Internet"). En
        utilisant le site Web d'Intrahealth, vous consentez à cette politique de
        confidentialité et à ses conditions. Intrahealth peut modifier cette
        politique de confidentialité de temps à autre. Il est de votre
        responsabilité de vérifier cette page Web périodiquement pour voir si
        des conditions ont été changées ou modifiées. Votre utilisation continue
        du site Web Intrahealth constitue votre acceptation de toute mise à jour
        de cette politique de confidentialité.
      </Typography>
      <Typography variant='h2'>
        Comment Intrahealth collecte-t-elle vos informations?
      </Typography>
      <Typography variant='body1'>
        Intrahealth recueille des informations auprès de vous de trois manières:
        (1) directement auprès de vous, (2) à partir des journaux du serveur Web
        d'Intrahealth et (3) des cookies.
        <ol>
          <li>
            Informations que vous nous donnez: nous obtenons des informations
            vous concernant lorsque vous utilisez le site Web Intrahealth,
            lorsque vous nous contactez à propos de produits et services,
            lorsque vous nous envoyez un message, ou lorsque vous choisissez
            (opt-in) de vous abonner à notre blog, newsletter, ou e-mails de
            notification d'événement. Dans ces cas, vous décidez quelles
            informations vous souhaitez partager et en quelle quantité.
          </li>
          <li>
            Journaux du serveur Web Intrahealth: une adresse IP est un numéro
            attribué automatiquement à votre ordinateur chaque fois que vous
            accédez à Internet. Les adresses IP permettent aux ordinateurs et
            aux serveurs de se reconnaître et de communiquer entre eux.
            Intrahealth recueille les adresses IP afin de procéder à
            l'administration du système et à l'analyse du site, afin d'améliorer
            la fonctionnalité et l'utilisation du site Web d'Intrahealth.
          </li>
          <li>
            Cookies: pour plus d'informations sur les cookies, consultez la
            politique de cookies d'Intrahealth.
          </li>
        </ol>
      </Typography>
      <Typography variant='h2'>
        Quelles informations recueille Intrahealth?
      </Typography>
      <Typography variant='body1'>
        Les informations que nous collectons peuvent inclure:
        <ul>
          <li>adresse IP</li>
          <li>Type et version du navigateur</li>
          <li>
            Données sur les équipements utilisés pour visiter le site Internet
            d'Intrahealth
          </li>
          <li>
            Modèles de recherche et de navigation sur le site Web d'Intrahealth
          </li>
        </ul>
      </Typography>

      <Typography variant='h2'>
        Comment Intrahealth utilise-t-il vos informations?
      </Typography>
      <Typography variant='body1'>
        Nous pouvons utiliser vos informations:
        <ul>
          <li>
            pour améliorer la fonctionnalité et l'utilisation du site Web
            d'Intrahealth
          </li>
          <li>
            pour faciliter le marketing, la promotion et l'information
            d'Intrahealth
          </li>
          <li>
            suivre la popularité des fonctionnalités sur le site Web
            d'Intrahealth et guider le développement de nouvelles
            fonctionnalités
          </li>
          <li>
            pour identifier les types d'appareils que nos visiteurs utilisent
            afin que nous puissions améliorer et optimiser nos systèmes
          </li>
        </ul>
      </Typography>
      <Typography variant='h2'>
        Intrahealth partagera-t-il vos informations?
      </Typography>
      <Typography variant='body1'>
        Nous ne vendrons, n'échangerons ou ne louerons jamais vos informations
        d'identification personnelle à des tiers.
        <br /> <br />
        Intrahealth fait appel à certains fournisseurs de services, tels que
        Google Analytics, à des fins de suivi et d'association du comportement
        de recherche et de navigation sur Internet à nos publicités, et pour
        fournir des fonctionnalités sur le site Web d'Intrahealth. Nous
        permettons à ces fournisseurs de services d'utiliser des technologies de
        suivi sur ou en conjonction avec le site Web. L'utilisation et la
        collecte d'informations par ces fournisseurs de services sont régies par
        leurs déclarations de confidentialité respectives et ne sont donc pas
        couvertes par la présente politique. Pour en savoir plus sur leurs
        politiques respectives, consultez les conditions d'utilisation de Google
        Analyticset politique de confidentialité de Google.
        <br /> <br />
        En outre, nous pouvons partager les informations d'utilisation du site
        Web Intrahealth avec ces fournisseurs de services, qui sont responsables
        de l'hébergement des données, pour identifier les tendances et
        déterminer l'orientation du marché. Les informations obtenues via ces
        processus peuvent être combinées avec des informations personnellement
        identifiables (telles que le nom, l'adresse et le numéro de téléphone)
        afin d'analyser nos efforts de marketing. Nous ne partagerons les
        informations personnellement identifiables qu'avec des fournisseurs,
        consultants, agents, partenaires et autres prestataires de services
        tiers avec lesquels nous nous engageons pour nous aider à fournir ou à
        améliorer les services Intrahealth.
      </Typography>
      <Typography variant='h2'>
        Choix / Opt-In
      </Typography>
      <Typography variant='body1'>
        La communication automatisée est limitée aux seules informations que
        vous avez demandées, telles que les rappels pour un événement ou un
        webinaire à venir, ou la livraison d'un livre blanc ou d'une étude de
        cas. Si vous souhaitez une communication plus régulière d'Intrahealth,
        vous pouvez choisir de vous abonner à notre blog, notre newsletter ou
        nos e-mails de notification d'événements. Toutes ces fonctionnalités
        incluent également une fonction de désabonnement, vous permettant de
        vous désinscrire à tout moment.
      </Typography>
      <Typography variant='h2'>
        Comment Intrahealth protège-t-il vos informations?
      </Typography>
      <Typography variant='body1'>
        La sécurité de vos informations et données lors de l'utilisation du site
        Web Intrahealth est très importante pour nous. Nous mettons en œuvre des
        pratiques appropriées de collecte, de stockage et de traitement des
        données et des mesures de sécurité pour vous protéger contre l'accès, la
        modification, la divulgation ou la destruction non autorisés de vos
        informations personnelles, nom d'utilisateur, mot de passe, informations
        de transaction et données stockées sur le site Web d'Intrahealth.
        <br /> <br />
        L'échange de données sensibles et privées entre le site Internet
        d'Intrahealth et ses utilisateurs est crypté et protégé via un canal de
        communication sécurisé SSL. Intrahealth se tient à jour avec les
        derniers protocoles de chiffrement et ne prend pas en charge les
        protocoles obsolètes et vulnérables.
      </Typography>
      <Typography variant='h2'>
        Liens tiers
      </Typography>
      <Typography variant='body1'>
        Le site Web Intrahealth peut contenir des liens vers des sites Web
        tiers. Ces sites Web sont régis par leurs propres politiques de
        confidentialité respectives. Nous vous encourageons à consulter les
        politiques de confidentialité des sites Web tiers pour comprendre leur
        collecte de données et d'informations, ainsi que leurs pratiques
        d'utilisation.
      </Typography>
      <Typography variant='h2'>
        Informations de contact
      </Typography>
      <Typography variant='body1'>
        Si vous avez des questions concernant cette politique de confidentialité
        ou notre traitement de vos informations, veuillez envoyer un courriel à
        privacy@intrahealth.com .
      </Typography>
    </Container>
  );
}

export default PrivacyFrench;
